<template>
    <div>
        <div class="flex flex-justify-center flex-align-end padding-tb-60" style="position:relative;">
            <div style="font-size: 24px;">新闻动态</div>
            <span
                @click="gotoList(0)"
                class="more">更多+</span>
        </div>


        <div class="flex flex-justify-center margin-tb-20" style="box-sizing: border-box;">
            <div class="flex-1">
                <el-carousel height="350px" style="width:450px">
                    <el-carousel-item v-for="item in 4" :key="item">
                        <img src="../assets/lunbo.png" style="height: 100%;width: 450px;object-fit: cover;" >
                        <!-- <div class="description" >
                            <a href="" style="color: white;font-size: 16px;opacity: .8;">
                            你好阿萨大厦阿松大阿松大啊
                            </a>
                        </div> -->
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="flex-2" >
                <ul style="margin-top: -12px;">
                    <li class="desclist" 
                            v-for="(newsItem) in NewsList" 
                            :key="newsItem.pid" 
                            @click="gotoDetail(newsItem.pid)">

                        <div class="flex flex-justify-around title-desc" style="flex: 8;">
                            <img src="../assets/point.png" alt="" style="width: 20px;height:20px;">
                            <span class="title-desc">{{ newsItem.name }}</span>
                        </div>
                        <div style="flex: 2;text-align: right;">
                            {{ newsItem.pubdate }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="flex flex-justify-center flex-align-end padding-tb-60" style="position:relative;">
            <div style="font-size: 24px;">智库展示</div>
            <span style="position: absolute;right:0;">
                <a href="" style="color: red;font-size:14px;font-weight:400">更多+</a>
            </span>
        </div>


        <div class="flex flex-justify-center margin-tb-20" style="box-sizing: border-box;">
            <div class="flex-1">
                <img src="../assets/teacher1.png" alt="" style="width: 100%;height:500px">
            </div>
            <div  class="flex-1 margin-lr-20">
                <img src="../assets/teacher2.png" alt="" style="width: 100%;height:500px">
            </div>
            <div class="flex-1">
                <img src="../assets/teacher3.png" alt="" style="width: 100%;height:500px">
            </div>
        </div>


        <div class="flex flex-justify-center padding-tb-20">
            <div class="flex-1">
                <img src="../assets/yy.png" alt="" style="width: 100%;height: 500px">
            </div>
            <div class="flex-1" style="position: relative;">
                <img src="../assets/bgDescription.png" alt="" style="width: 650px;height: 500px;">
                <div style="position: absolute;top: 0;z-index: 999;color: aliceblue;">
                    <div style="font-size:26px;text-indent: 1em;" class="padding-lr-20 padding-tb-60">关于团结智库</div>
                    <div style="text-indent: 2em;letter-spacing: 2px;line-height: 26px;text-align: left;" class="padding-lr-20">
                        “团结智库”智媒体以团结命名、应团结而生，是非营利性、统一战线高端智库，是中国特色新型智库。
                        团结智库是经济统战工作的生动实践，是最广泛的爱国统一战线，是中华民族伟大复兴软实力的支撑载体。
                        团结智库以大团结、大联合为永恒的主题，充分发挥统战人才与智力密集的优势，面向社会重大需求与经济主战场，
                        聚焦区域经济发展，开展以咨询为基础的公共政策研究、以祖统联谊为核心的民间外交，通过人才服务与社会服务，
                        凝心聚力，为党和国家中心任务提供广泛智力支持。
                    </div>
                    <div style="margin-top: 50px;text-align: end;" class="padding-lr-20">
                        <a href="" style="color: white;font-weight:bolder;">了解更多>></a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>    
import axios from "axios";
export default{
    name:'HomeInfo',
    data(){
        return{
            NewsList:[]
        }
    },
    methods:{
        gotoDetail(e){
            console.log(e)
            this.$router.push({
                path:`/DetailInfo/${e}`
            })
        },
        gotoList(e){
            debugger
            switch(e*1){
                
                case 0:
                    this.$router.push({
                        path:'/InfoList'
                    })
                    break;
            }
        },

        getInfoList(){
            axios({
                method:'get',
                url:'http://www.3a0598.com/api.php?op=cpmapinew&act=ajax_getinfo&pagesize=10&thispage=1&type=ipzcqb&timetype=-1&keywords='
                
            }).then(res=>{
                console.log(res)
                if(res.status == 200){
                    this.NewsList = res.data.data

                    this.NewsList.forEach(s=>{
                        
                        var date = new Date(s.pubdate).getTime()
                        date +=  8*60*60;
                        var newDate = new Date(date);
                        s.pubdate = newDate.getFullYear()+'-'+(newDate.getMonth()*1+1)+'-'+newDate.getDate()
                    })

                }
                
            })
        }
    },
    created(){
        this.getInfoList()
    }
}

</script>


<style scoped>

.description{
    margin-top: -65px;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 5px;
    font-size: 24px;
    background-color: #d7655a;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    position: relative;
    color: white;
}


.desclist{
    display: flex;
    justify-content: space-around;
    height: 38px;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
    align-items: center;
}

.desclist:hover{
    color: #d7655a;
}
.title-desc{
    flex: 2;overflow: hidden;text-wrap: nowrap;text-overflow: ellipsis;
}


.more{
    position: absolute;
    right:0;
    color: red;
    font-size:14px;
    font-weight:400;
    cursor: pointer;
}
</style>