var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex flex-justify-between flex-align-center"},[_vm._m(0),_c('div',{staticClass:"flex flex-align-center flex-3",staticStyle:{"flex-direction":"row-reverse"}},[_vm._m(1),_vm._m(2),_vm._m(3),_c('el-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入内容","prefix-icon":"el-icon-search"},model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}})],1)]),_c('div',{staticStyle:{"z-index":"10"}},[_c('el-carousel',{attrs:{"height":"400px"}},_vm._l((1),function(item,index){return _c('el-carousel-item',{key:index},[_c('div',[_c('img',{staticStyle:{"width":"100vw","height":"400px"},attrs:{"src":require("../assets/pic.png"),"alt":""}})])])}),1)],1),_vm._m(4),(_vm.$route.path != '/')?_c('keep-alive',[_c('div',{staticClass:"iconList-2"},[_c('router-view')],1)]):_c('div',{staticClass:"iconList-2"},[_c('HomeInfo')],1),_vm._m(5)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-align-center flex-5"},[_c('span',{staticClass:"flex-1 padding-tb-20 margin-lr-40"},[_c('img',{attrs:{"src":require("../assets/topicon.png"),"alt":""}})]),_c('span',{staticClass:"flex-1 padding-tb-20"},[_c('a',{attrs:{"href":""}},[_vm._v("团结咨询")])]),_c('span',{staticClass:"flex-1 padding-tb-20"},[_c('a',{attrs:{"href":""}},[_vm._v("团结数据")])]),_c('span',{staticClass:"flex-1 padding-tb-20"},[_c('a',{attrs:{"href":""}},[_vm._v("团结之家")])]),_c('span',{staticClass:"flex-1 padding-tb-20"},[_c('a',{attrs:{"href":""}},[_vm._v("团结文化")])]),_c('span',{staticClass:"flex-1 padding-tb-20"},[_c('a',{attrs:{"href":""}},[_vm._v("团结电商")])]),_c('span',{staticClass:"flex-1 padding-tb-20"},[_c('a',{attrs:{"href":""}},[_vm._v("团结基金")])]),_c('span',{staticClass:"flex-1 padding-tb-20"},[_c('a',{attrs:{"href":""}},[_vm._v("海峡IP")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"flex flex-direction-column flex-align-center padding-tb-20 EndFont"},[_c('span',{staticStyle:{"padding-bottom":"10px"}},[_vm._v("资讯热线")]),_c('span',[_vm._v("0791-88316800")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"padding-lr-20"},[_c('a',{attrs:{"href":""}},[_vm._v("登录")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"padding-lr-20"},[_c('a',{attrs:{"href":""}},[_vm._v("注册")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex iconList",staticStyle:{"background-color":"white","z-index":"99","box-sizing":"border-box"}},[_c('div',{staticClass:"flex flex-1"},[_c('img',{staticStyle:{"width":"50px","height":"50px","padding-right":"20px"},attrs:{"src":require("../assets/one.png"),"alt":""}}),_c('div',{staticClass:"flex flex-direction-column",staticStyle:{"justify-content":"flex-start"}},[_c('div',{staticClass:"title cursor-pointer"},[_vm._v("团结文化")]),_c('div',{staticClass:"title-2 cursor-pointer"},[_vm._v("为把文化人引向健康的文艺方向")]),_c('a',{staticClass:"title-3",attrs:{"href":"#"}},[_vm._v("免费下载>")])])]),_c('div',{staticClass:"flex flex-1"},[_c('img',{staticStyle:{"width":"50px","height":"50px","padding-right":"20px"},attrs:{"src":require("../assets/two.png"),"alt":""}}),_c('div',{staticClass:"flex flex-direction-column",staticStyle:{"justify-content":"flex-start"}},[_c('span',{staticClass:"title cursor-pointer"},[_vm._v("团结电商")]),_c('span',{staticClass:"title-2 cursor-pointer"},[_vm._v("元代国家的宗教政策，在巩固其政")]),_c('a',{staticClass:"title-3",attrs:{"href":"#"}},[_vm._v("免费下载>")])])]),_c('div',{staticClass:"flex flex-1"},[_c('img',{staticStyle:{"width":"50px","height":"50px","padding-right":"20px"},attrs:{"src":require("../assets/three.png"),"alt":""}}),_c('div',{staticClass:"flex flex-direction-column",staticStyle:{"justify-content":"flex-start"}},[_c('span',{staticClass:"title cursor-pointer"},[_vm._v("团结基金")]),_c('span',{staticClass:"title-2 cursor-pointer"},[_vm._v("地方性不等于排他性，对分众化的")]),_c('a',{staticClass:"title-3",attrs:{"href":"#"}},[_vm._v("免费下载>")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('div',{staticClass:"flex padding-tb-60",staticStyle:{"justify-content":"space-around","border-bottom":"2px solid #4a4a4a"}},[_c('div',{staticClass:"bottom-text"},[_c('span',{staticClass:"padding-tb-10"},[_vm._v("关于我们")]),_c('span',{staticStyle:{"color":"#4a4a4a"}},[_vm._v("公司介绍")]),_c('span',{staticStyle:{"color":"#4a4a4a"}},[_vm._v("加入我们")])]),_c('div',{staticClass:"bottom-text"},[_c('span',{staticClass:"padding-tb-10"},[_vm._v("联系我们")]),_c('span',{staticStyle:{"color":"#4a4a4a"}},[_vm._v("总机电话:+8610-8236-3782/官方客服:400-125-9889")]),_c('span',{staticStyle:{"color":"#4a4a4a"}},[_vm._v("地址:北京市海淀区成府路28号优胜大厦C座17层")])]),_c('img',{attrs:{"src":"","alt":""}})]),_c('div',{staticClass:"flex padding-tb-20",staticStyle:{"justify-content":"space-around","font-size":"14px"}},[_c('span',{staticStyle:{"color":"#4a4a4a"}},[_vm._v("阿依瓦(北京)技术有限公司@版权所有")]),_c('span',{staticStyle:{"color":"#4a4a4a"}},[_vm._v("京ICP备16062079号")]),_c('span',{staticStyle:{"color":"#4a4a4a"}},[_vm._v("备案号xxxxxx")])])])
}]

export { render, staticRenderFns }