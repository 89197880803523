<template>
  <div>

    <div class="flex flex-justify-between flex-align-center">
      <div class="flex flex-align-center flex-5">
        <span class="flex-1 padding-tb-20 margin-lr-40">
          <img src="../assets/topicon.png" alt="">
        </span>
        <span class="flex-1 padding-tb-20">
          <a href="">团结咨询</a>
        </span>
        <span class="flex-1 padding-tb-20">
          <a href="">团结数据</a>
        </span>
        <span class="flex-1 padding-tb-20">
          <a href="">团结之家</a>
        </span>
        <span class="flex-1 padding-tb-20">
          <a href="">团结文化</a>
        </span>
        <span class="flex-1 padding-tb-20">
          <a href="">团结电商</a>
        </span>
        <span class="flex-1 padding-tb-20">
          <a href="">团结基金</a>
        </span>
        <span class="flex-1 padding-tb-20">
          <a href="">海峡IP</a>
        </span>
      </div>
      <div class="flex flex-align-center flex-3" style="flex-direction: row-reverse;">
        <span class="flex flex-direction-column flex-align-center padding-tb-20 EndFont" >
          <span style="padding-bottom:10px">资讯热线</span>
          <span>0791-88316800</span>
        </span>
        <span class="padding-lr-20">
          <a href="">登录</a>
        </span>
        <span class="padding-lr-20">
          <a href="">注册</a>
        </span>
        <el-input
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          v-model="searchData" style="width: 200px;">
        </el-input>
      </div>
    </div>

    <div style="z-index:10 ;">
      <el-carousel height="400px">
        <el-carousel-item v-for="(item,index) in 1" :key="index">
          <div>
            <img src="../assets/pic.png" alt="" style="width: 100vw;height:400px"> 
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="flex iconList" style="background-color: white;z-index: 99;box-sizing: border-box;">
      <div class="flex flex-1">
        <img src="../assets/one.png" alt="" style="width: 50px;height:50px;padding-right: 20px;">
        <div class="flex flex-direction-column" style="justify-content: flex-start;">
          <div class="title cursor-pointer">团结文化</div>
          <div class="title-2 cursor-pointer">为把文化人引向健康的文艺方向</div>
          <a href="#" class="title-3">免费下载></a>
        </div>
      </div>
      <div class="flex flex-1">
        <img src="../assets/two.png" alt="" style="width: 50px;height:50px;padding-right: 20px;">
        <div class="flex flex-direction-column" style="justify-content: flex-start;">
          <span class="title cursor-pointer">团结电商</span>
          <span class="title-2 cursor-pointer">元代国家的宗教政策，在巩固其政</span>
          <a href="#" class="title-3">免费下载></a>
        </div>
      </div>
      <div class="flex flex-1">
        <img src="../assets/three.png" alt="" style="width: 50px;height:50px;padding-right: 20px;">
        <div class="flex flex-direction-column" style="justify-content: flex-start;">
          <span class="title cursor-pointer">团结基金</span>
          <span class="title-2 cursor-pointer">地方性不等于排他性，对分众化的</span>
          <a href="#" class="title-3">免费下载></a>
        </div>
      </div>
    </div>

    <keep-alive v-if="$route.path != '/'" >
      <div class="iconList-2">
        <router-view />
      </div>
    </keep-alive>

    <div v-else class="iconList-2">
      <HomeInfo></HomeInfo>
    </div>


    <div class="bottom">
      <div class="flex padding-tb-60" style="justify-content: space-around;border-bottom: 2px solid #4a4a4a;">
        <div class="bottom-text">
          <span class="padding-tb-10">关于我们</span>
          <span style="color: #4a4a4a;">公司介绍</span>
          <span style="color: #4a4a4a;">加入我们</span>
        </div>
        <div class="bottom-text">
          <span class="padding-tb-10">联系我们</span>
          <span style="color: #4a4a4a;">总机电话:+8610-8236-3782/官方客服:400-125-9889</span>
          <span style="color: #4a4a4a;">地址:北京市海淀区成府路28号优胜大厦C座17层</span>
        </div>
        <!-- 预留图片位置 也不知道 要干嘛 -->
        <img src="" alt="">
      </div>

      <div class="flex padding-tb-20" style="justify-content: space-around;font-size: 14px;">
        <span style="color: #4a4a4a;">阿依瓦(北京)技术有限公司@版权所有</span>
        <span style="color: #4a4a4a;">京ICP备16062079号</span>
        <span style="color: #4a4a4a;">备案号xxxxxx</span>
      </div>
      
    </div>

  </div>
  
</template>

<script>
// @ is an alias to /src
import HomeInfo from '@/components/HomeInfo.vue'
export default {
  name: 'Home',
  components:{
    HomeInfo
  },
  data(){
    return{
      searchData:'',
      imgList:[{
        imgSrc:"http://localhost:8080/assets/pic.png"
      }]
    }
  }
}


</script>


<style scoped>
.bottom{
  background: black;
  box-sizing: border-box;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
}
.EndFont{
  background-color: red;
  color:white;
  padding-right: 50px;
  padding-left: 10px;
  align-items: start;
}

.iconList{
  border-radius: 20px;
  width: 65%;
  margin-left:auto ;
  margin-right: auto;
  padding:20px ;
  margin-top: -50px;
  position: relative;
  z-index: 999;
}
.iconList-2{
  width: 65%;
  margin-left: auto;
  margin-right: auto;
}
.title{
  font-size: 18px;font-weight:550;
}
.title-2{
  font-size: 14px;
  color: #363636;
  padding: 6px 0;
}
.title-3{
  font-size: 14px;
  display: block;
  padding:5px;
  letter-spacing: 2px;
  background-color:#fec2bf ;
  color:#e75851 ;
  width: 80px;
  border-radius: 5px;
}

.bottom-text{
  color: white;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  }
</style>
