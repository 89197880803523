<template>
    <div class="box margin-tb-20 padding-lr-20 padding-tb-20">
        <div class="textAlign font-26">{{ detailInfo.Name }}</div>
        <div class="textAlign font-16 padding-tb-20" style="padding-left: 100px;padding-top:20px;color: #363633;">{{ detailInfo.Pubdate.split(' ')[0] }}</div>
        <div v-html="detailInfo.despription" style="width: 100%;overflow-x: auto;"></div>
    </div>
</template>

<script>
import axios from "axios";
export default{
    name:'DetailInfo',
    data(){
        return{
            content:``,
            detailInfo:{
                Name:'',
                despription:'',
                Pubdate:''
            }
        }
    },
    methods:{
        getDeatailByID(id){
            console.log(id)

            axios({
                method:'get',
                url:`http://www.3a0598.com/api.php?op=cpmapinew&act=togetDetail&pid=${id}`
            }).then(res=>{
                if(res.status == 200){
                    console.log(res)
                    this.detailInfo = {
                        Name:res.data.data.Name,
                        despription:res.data.data.despription,
                        Pubdate:res.data.data.Pubdate
                    }
                }
            })
        }
    },
    created(){
        console.log(this.$route.params)
        // 调用接口 获取数据
        this.getDeatailByID(this.$route.params.id)
    }
}

</script>


<style scoped>
.box{
    min-height: 50vh;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
}
/deep/ img{
    width: 100% !important;
}
</style>