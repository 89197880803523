<template>
    <div class="box margin-tb-20 padding-lr-20 padding-tb-20">
        <ul>
            <li class="desclist" 
                v-for="(newsItem) in InfoList" 
                :key="newsItem.pid" 
                @click="gotoDetail(newsItem.pid)">
                <div class="flex">
                    <img src="../../assets/point.png" alt="" style="width: 20px;height:20px;">
                    <span >{{ newsItem.name }}</span>
                </div>
                <div style="">
                    {{ newsItem.pubdate }}
                </div>
            </li>
        </ul>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[20, 50, 100, 200]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.pageCount">
        </el-pagination>
    </div>
</template>


<script>
    import axios from 'axios'
    export default{
        name:'InfoList',
        data(){
            return {
                InfoList:[],
                //分页
                page:{
                    pageIndex:1,
                    pageSize:20,
                    pageCount:200
                }
            }
        },
        methods:{
            getInfoList(){
                axios({
                    method:'get',
                    url:`http://www.3a0598.com/api.php?op=cpmapinew&act=ajax_getinfo&pagesize=${this.page.pageSize}
                        &thispage=${this.page.pageIndex}&type=ipzcqb&timetype=-1&keywords=`
                }).then(res=>{
                    console.log(res)
                    if(res.status == 200){
                        this.InfoList = res.data.data

                        this.InfoList.forEach(s=>{
                            var date = new Date(s.pubdate).getTime()
                            date +=  8*60*60;
                            var newDate = new Date(date);
                            s.pubdate = newDate.getFullYear()+'-'+(newDate.getMonth()*1+1)+'-'+newDate.getDate()
                        })
                        this.page.pageCount = res.data.totalnum
                    }
                })
            },

            handleCurrentChange(e){
                this.page.pageIndex = e
                this.getInfoList()
            },
            handleSizeChange(e){
                this.page.pageSize = e
                this.getInfoList()
            },
            gotoDetail(e){
                console.log(e)
                this.$router.push({
                    path:`/DetailInfo/${e}`
                })
            }
        },
        created(){
            this.getInfoList();
        }
    }

</script>


<style scoped>
.box{
    min-height: 50vh;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
}

.desclist{
    display: flex;
    justify-content: space-between;
    height: 38px;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
    align-items: center;
}
.title-desc{
    flex: 2;overflow: hidden;text-wrap: nowrap;text-overflow: ellipsis;
}

</style>